import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useStaffList(root) {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)
  const programId = computed(() => store.getters['verticalMenu/getDefaultProgram'])

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'name', sortable: true },
    { key: 'gender', label: 'gender', sortable: true },
    { key: 'language', label: 'language', sortable: true },
    { key: 'recent_program', label: 'recent program', sortable: true },
    { key: 'actions' },
  ]

  const defaultPerPage = Number(localStorage.getItem('staffPerPage'))
  const perPage = ref(defaultPerPage || DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const filterLanguageOptions = ['English', 'Hebrew']
  const filterDataLanguage = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  let debounceTimeout = ref(null)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const clearFilters = () => {
    filterDataLanguage.value = ''
  }
  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch(programId, () => {
    refetchData()
  })

  watch([currentPage, perPage, searchQuery, filterDataLanguage],
    ([currentPageVal, perPageVal, searchQueryVal, filterDataLanguageVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal, filterDataLanguagePrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-staff', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-staff', 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-staff', 'searchQuery', searchQueryVal)
        }, 250)
      }
      if (filterDataLanguageVal !== filterDataLanguagePrevVal) {
        pushStateFiltersParams(root, 'admin-staff', 'filterDataLanguage', filterDataLanguageVal)
      }
    })

  const fetchStaffList = (ctx, callback) => {
    const query = { ...root.$route.query }
    const payloadData = {
      perPage: perPage.value,
      page: query.currentPage || 1,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      // programId: programId.value,
    }
    if (searchQuery.value) payloadData.name = searchQuery.value
    if (filterDataLanguage.value) payloadData.language = filterDataLanguage.value
    store
      .dispatch('app-staff/fetchStaffList', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta
        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching staff list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const exportStaff = queryParams => axios
    .get('/auth/users/export', { params: queryParams })
    .then(response => response)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error exporting users',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const actionOptions = [
    { value: 'edit', title: 'Edit', link: 'admin-staff-profile' },
  ]

  return {
    fetchStaffList,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    filterLanguageOptions,
    filterDataLanguage,
    sortBy,
    totalItems,
    isSortDirDesc,
    refInvoiceListTable,

    actionOptions,

    refetchData,
    clearFilters,
    exportStaff,
  }
}
